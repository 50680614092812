import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as studentActions } from '../../../../redux/modules/student';

import Title from '../../../../components/ReportCFP/Title';

import CircleChart from '../../../../components/CircleChart';
import BarChart from '../../../../components/BarChart';

import { getPercentFormatted } from '../../../../shared/utils/common';

import {
    getDefaultParams,
} from '../../common';

import './styles.scss';


function Score() {
    const {
        reportSimulado: report
    } = useSelector((state) => state.student);

    const dispatch = useDispatch();

    useEffect(() => {
        const { userId, courseId, classId, media, start, end, kind, classes } = getDefaultParams(window.location.search);
        dispatch(studentActions.handleCfpSimulado(
            userId,
            courseId,
            classId,
            media,
            start,
            end,
            kind,
            classes
        ));
        // eslint-disable-next-line
    }, []);

    const classDone = report?.test?.done?.[0]?.prova ? report?.test?.done?.[0]?.prova : 0;
    const classToBeDone = report?.test?.to_be_done?.[0]?.prova ?? 31;
    const totalClassesToBeDone = `${classToBeDone}*`;
    const percentDone = classToBeDone === 0 ? 0 : (classDone * 100) / classToBeDone;

    return (
        <div className="weekly-png">
            <main className="weekly-png__main">
                <section className="weekly-png__section-1">
                    <div className="weekly-png__section-1__title">
                        <Title
                            description="Média Geral"
                        />
                        <Title
                            description="Provas Feitas"
                        />
                        <Title
                            description="Score Toro"
                        />
                    </div>

                    <div className="weekly-png__section-1__graphs">
                        <div className="weekly-png__section-1__box">
                            <div className="weekly-png__section-1__box-circle-chart" style={{ transform: 'scale(3)' }}>
                                <CircleChart
                                    percent={getPercentFormatted(report?.average?.acertos_avg)}
                                    animation={false}
                                    fontColor={'#000A66'}
                                    responsive={false}
                                />
                            </div>
                        </div>
                        <div className="weekly-png__section-1__box">
                            <div className="progress-bar">
                                <h3>{classDone}/{totalClassesToBeDone}</h3>
                                <BarChart
                                    percent={percentDone}
                                    showPercent={false}
                                />
                                <p className="weekly-png__section-1__box-description">
                                    *Número de Provas da 1ª etapa do curso CFP®60 dias.
                                </p>

                                {(percentDone <= 0) ? <p data-test-id="initial-text" className="weekly-png__section-1__box-init">
                                    Para iniciar as aulas,
                                    <br />
                                    contacte o seu professor.
                                </p> : null}
                            </div>
                        </div>
                        <div className="weekly-png__section-1__box">
                            <div className="weekly-png__section-1__box-circle-chart" style={{ transform: 'scale(3)' }}>
                                <CircleChart
                                    percent={getPercentFormatted(report?.score?.score)}
                                    animation={false}
                                    fontColor={'#000A66'}
                                    responsive={false}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Score;
