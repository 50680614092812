import React from 'react';
import TextsHeader from '../TextsHeader';

import { Legend, Min, Recomended } from '../graphs/styles';
import ChartBarTotal from '../graphs/ChartBarTotal';
import { Col } from 'antd';

import {
  Container,
  Content,
  ColContent,
  Middle,
  LineDashed,
  ColM,
  Line,
  LineDashedTop
} from './styles'
import TextSuggestion from './TextSuggestion';
// import { isEmpty } from 'lodash';

function GeralResults(props) {
  const RECOMMENDED_ART = [{
                            module: "M1",
                            value: 80
                          },
                          {
                            module: "M2",
                            value: 75
                          },
                          {
                            module: "M3",
                            value: 80
                          },
                          {
                            module: "M4",
                            value: 80
                          },
                          {
                            module: "M5",
                            value: 75
                          },
                          {
                            module: "M6",
                            value: 75
                          }]

  const data = props.data?.module_performance_simulate ?? [];
  const toImprove = props?.data?.you_can_improve_simulated ?? [];

  toImprove.sort((a, b) => {
    return a.module - b.module;
  });

  let modulesTotal = [0, 0, 0, 0, 0, 0];
  const completeNumbers = [0, 1, 2, 3, 4, 5] ?? [];

  completeNumbers.map((item, x) => {
    if (Object.values(data)[x] !== undefined) {
      modulesTotal[parseInt(Object.values(data)[x].modules.substr(1)) - 1] = Object.values(data)[x];
    }
    return 0;
  });

  // const toImproveModule = toImprove.filter(item => item.percentage > 0).map(item => item.module);

  // const toImproveNumbers = toImproveModule.slice(0, -1).join(', ');
  // const toImproveLastNumber = toImproveModule[toImproveModule.length - 1];
  // const toImproveFormatted = toImproveNumbers + ' e ' + toImproveLastNumber;

  // const oneNumberFormatted = toImproveLastNumber === undefined ? '' : toImproveLastNumber.length !== 1 ? toImproveFormatted.replace(' e ', '') : toImproveFormatted;
  // const resultGeralFormatted = oneNumberFormatted.length === 4 ? toImproveFormatted.replace(' e ', '') : toImproveFormatted;

  // const simulated = '1, 2, 3, 4, 5 e 6';
  // const simulatedMock = [
  //   { module: '1', percentage: 100 },
  //   { module: '2', percentage: 100 },
  //   { module: '3', percentage: 100 },
  //   { module: '4', percentage: 100 },
  //   { module: '5', percentage: 100 },
  //   { module: '6', percentage: 100 }
  // ];

  const moduleToImprove = modulesTotal?.filter((item) => {
    const index = RECOMMENDED_ART.findIndex((element) => element.module === item?.modules);
    return RECOMMENDED_ART[index]?.value - item?.nota_percentual > 0
  });
  const moduleToImproveArray = moduleToImprove.map(item => item.modules[1]);
  let modulesToImprove = '';
  if (moduleToImproveArray.length > 1) {
    modulesToImprove = moduleToImproveArray.slice(0, -1).join(', ') + ' e ' + moduleToImproveArray.slice(-1);
  } else {
    modulesToImprove = moduleToImproveArray.join(', ');
  }
  const calculateImproveModule = (item) => {
    const index = RECOMMENDED_ART.findIndex((element) => element.module === item.modules);
    return  Math.round(RECOMMENDED_ART[index]?.value - item?.nota_percentual);
  }

  return (
    <Container style={{marginTop: "80px"}}>
      <TextsHeader>
        <p>Agora veja abaixo o seu desempenho de forma geral em todos os simulados, comparando com nossa recomendação e também comparando com o mínimo de aprovação:</p>
      </TextsHeader>

      <Content>
        <ColContent xs={24} className="reportSeven">
          {modulesTotal?.map((item, i) => {
            let recomended
            if (i === 0) {
              recomended = RECOMMENDED_ART[0]?.value
            } else if (i === 1) {
              recomended = RECOMMENDED_ART[1]?.value
            } else if (i === 2) {
              recomended = RECOMMENDED_ART[2]?.value
            } else if (i === 3) {
              recomended = RECOMMENDED_ART[3]?.value
            } else if (i === 4) {
              recomended = RECOMMENDED_ART[4]?.value
            } else if (i === 5) {
              recomended = RECOMMENDED_ART[5]?.value
            }

            return (
              <>
                {
                  item?.nota_percentual <= 0
                    ? (<ChartBarTotal result={(0)} margin={1} recomended={recomended} />)
                    : (item?.nota_percentual <= 10
                      ? (<ChartBarTotal result={Math.round(100)} margin={1} recomended={recomended} />)
                      : (item?.nota_percentual > 100
                        ? (<ChartBarTotal result={(100)} margin={1} recomended={recomended} />)
                        : (<ChartBarTotal result={Math.round(item?.nota_percentual)} margin={1} recomended={recomended} />)
                      )
                    )
                }
              </>
            )
          })}
          <Middle style={{ marginLeft: '-6vw' }}>
            <LineDashedTop />
            <p>50%</p>
            <LineDashed />
          </Middle>
        </ColContent>

        <Line />

        <ColM>
          {
            completeNumbers.map((item => {
              return <h3>M{item+1}</h3>
            }))
          }
        </ColM>
        <Col xs={24}>
          <Legend style={{ marginTop: '1.760vw', justifyContent: 'flex-end' }}>
            <Min>
              <div />
              <p>seu desempenho</p>
            </Min>
            <Recomended>
              <div />
              <p>recomendado</p>
            </Recomended>
          </Legend>
        </Col>
      </Content>

      <div style={{ marginTop: '3vw' }}>
      {/* {
          isEmpty(toImprove) ?
          <TextSuggestion>
            Você precisa dar mais ênfase aos módulos<span> {simulated}</span>. Para ser mais exato você precisa: <br />
          </TextSuggestion>
          :
          isEmpty(oneNumberFormatted) ? (' ') : (
            <TextSuggestion>
              Você precisa dar mais ênfase aos módulos<span> {isEmpty(toImprove) ? simulated : resultGeralFormatted}</span>. Para ser mais exato você precisa: <br />
            </TextSuggestion>)
        } */}

        <TextSuggestion>
          {/* {isEmpty(toImprove)?
            (
              simulatedMock.map((item) =>
                <>
                  Módulo <span>{item?.module}</span>: aumentar <span>{(item?.percentage ?? 100)}%</span>.<br />
                </>
              )
            ) : (
              toImprove.filter(item => item.percentage > 0).map((item) =>
                <>
                  Módulo <span>{item?.module}</span>: aumentar <span>{(item?.percentage ?? 100)}%</span>.<br />
                </>
              )
            )} */}

            {moduleToImprove?.length > 0 ? (
              <>
                <TextSuggestion>
                  Para atingir a média RECOMENDADA, você precisa evoluir nos módulos: <span> {modulesToImprove}</span>. <br />
                </TextSuggestion>
              </>
            ) : null}

            {moduleToImprove?.map((item, index) =>
              <>
                Módulo <span>{item?.modules[1]}</span>: aumentar <span>{calculateImproveModule(item)}%</span>.<br />
              </>
            )}
        </TextSuggestion>
      </div>
    </Container>
  );
};

export default GeralResults;
