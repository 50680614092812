import React, { useContext, useEffect, useState } from 'react'


import { array, bool, func } from 'prop-types'
import { connect } from 'react-redux'
import { Button, Row, Col, List, Spin } from 'antd'
import Lecture from '../lecture/Lecture.component'
import ClassLectureResult from '../lecture/LectureResult.component'
import ClassLectureReview from '../lecture/LectureReview.component'
import FinishedLectureList from './components/FinishedLectureList.component'
import { DownloadButtonComponent } from '../../DownloadButtonComponent'

//
// context
import { CourseContext } from '../../../ClassContent/CourseDetail.container'

//
// redux
import * as courseSelector from '../../../../../redux/selectors/course'
import * as classSelectors from '../../../../../redux/selectors/class'
import { actions as classActions } from '../../../../../redux/modules/class'

import { useHistory, useParams } from 'react-router-dom'


import './styles.css'

const propTypes = {
  getMocklListById: func,
  getFinishedLectures: func.isRequired,
  getFinishedMocks: func.isRequired,
  isLoading: bool.isRequired,
  mockStructure: array,
}

function DesktopMockContent({
  getFinishedMocks,
  getMocklListById,
  isLoading,
  mockStructure
}) {
  const {courseId, courseClassId} = useParams();
  const history = useHistory()
  const {
    classStep,
    selectedClass,
    handlePrintTest,
    loadingDownloadText,
    course
  } = useContext(CourseContext)
  const [lectureStarted, setLectureStarted] = useState(false)

  useEffect(() => {
    getFinishedMocks(getMocklListById(selectedClass.id_aula_simulado_grupo))
    // eslint-disable-next-line
  }, [selectedClass])

  const handleReview = (lectureId, idAula) => {
    history.push(`/lectures/${courseId}/${courseClassId}/${lectureId}`, {selectedClass, course, idAula})
  }

  return (
    <Spin spinning={isLoading}>
      {!isLoading && (
        <List
          dataSource={mockStructure}
          style={{ marginTop: 20 }}
          renderItem={mock => {
            return (
              <List.Item className='header-simulated'>
                  <div className='title-simulated'>{mock.titulo}</div>
                <Row justify="space-between" style={{width: "100%"}}>
                  <Col>
                    <Button onClick={() => history.push(`/lectures/${courseId}/${courseClassId}`, {selectedClass: mock || selectedClass, course})}>Fazer o simulado</Button>
                  </Col>
                  <Col>
                    <DownloadButtonComponent loading={loadingDownloadText} handleDownload={() => handlePrintTest(mock.id)} />
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col>Simulados realizados:</Col>
                </Row>
                <Row>
                  <Col>
                    <FinishedLectureList
                      finishedLectures={mock.finishedMocks}
                      handleReview={handleReview}
                    />
                  </Col>
                </Row>
              </List.Item>
            )
          }}
        />
      )}
      <Lecture
        lectureStarted={lectureStarted}
        setLectureStarted={setLectureStarted}
      />
      {classStep === 'lectureResult' && (
        <ClassLectureResult />
      )}
      {classStep === 'lectureReview' && (
        <ClassLectureReview />
      )}
    </Spin>
  )
}

const mapStateToProps = (state, props) => ({
  getMocklListById: courseSelector.getMocklListById(state, props),
  finishedLectures: classSelectors.finishedLectures(state, props),
  isLoading: classSelectors.isLoading(state, props),
  mockStructure: classSelectors.mockStructure(state, props),
})

const mapDispatchToProps = (dispatch) => ({
  getFinishedMocks: (mocks, next) => dispatch(classActions.getFinishedMocks(mocks, next)),
})

DesktopMockContent.propTypes = propTypes
export default connect(mapStateToProps, mapDispatchToProps)(DesktopMockContent)
