import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ClassRight from '../ClassRight.component'
import { FormOutlined } from '@ant-design/icons'
import Lecture from '../lecture/Lecture.component'
import React, { useState, useEffect, useContext } from 'react'
import { CourseContext } from '../../../ClassContent/CourseDetail.container'

//
// redux + selectors
import * as classSelectors from '../../../../../redux/selectors/class'
import { actions as classActions } from '../../../../../redux/modules/class'
import { actions as courseActions } from '../../../../../redux/modules/course'
import FinishedLectureList from './components/FinishedLectureList.component'
import {  useParams } from 'react-router-dom'
import history from '../../../../../history'

const propTypes = {
  user: PropTypes.object,
  redo: PropTypes.bool,
  getFinishedLectures: PropTypes.func.isRequired,
  finishedLectures: PropTypes.array,
  setClassProperties: PropTypes.func.isRequired,
}

function DesktopLecture(props) {
  const {courseId, courseClassId} = useParams();
  const [lectureStarted, setLectureStarted] = useState(false)
  //
  const { selectedClass, handlePrintTest, loadingDownloadText, course} = useContext(CourseContext)

  useEffect(() => {
    const { getFinishedLectures } = props
    getFinishedLectures(selectedClass.id)
    // eslint-disable-next-line
  }, [selectedClass])

  const handleReview = (lectureId, idAula) => {
    history.push(`/lectures/${courseId}/${courseClassId}/${lectureId}`, {selectedClass, course, idAula})
  }
  
  const { redo, finishedLectures } = props

  return (
    <>
      <ClassRight
        sectionTitle={redo ? 'Refaça as questões erradas da prova' : 'Faça a prova'}
        content={
          !redo ? (
            <>
              Teste seus conhecimentos,
              <br />
              faça a prova!
            </>
          ) : undefined
        }
        loadingDownloadText={loadingDownloadText}
        handlePrintTest={handlePrintTest}
        icon={<FormOutlined/>}
        buttonText={redo ? 'Clique aqui para iniciar a prova' : 'Iniciar'}
        handleClick={() => {
          history.push(`/lectures/${courseId}/${courseClassId}`, {selectedClass, course})
        }}
      />
      {!redo && (
        <ClassRight
          sectionTitle="Provas realizadas"
          content={
            <FinishedLectureList
              finishedLectures={finishedLectures}
              handleReview={handleReview}
            />
          }
        />
      )}
      <Lecture redo={redo} lectureStarted={lectureStarted} setLectureStarted={setLectureStarted} />
    </>
  )
}

const mapStateToProps = (state, props) => ({
  finishedLectures: classSelectors.finishedLectures(state, props),
  isLoading: classSelectors.isLoading(state, props),
})

const mapDispatchToProps = (dispatch) => ({
  getFinishedLectures: (classId, next) =>
    dispatch(classActions.getFinishedLectures(classId, next)),
  setClassProperties: (courseId, step, next) =>
    dispatch(courseActions.setClassProperties(courseId, step, next)),
})

DesktopLecture.propTypes = propTypes
export default connect(mapStateToProps, mapDispatchToProps)(DesktopLecture)
