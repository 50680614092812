import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Form, Row, List } from 'antd';
import { 
  AppstoreOutlined, 
  AuditOutlined, 
  ArrowDownOutlined, 
  ArrowUpOutlined 
} from '@ant-design/icons';
import { 
  RowStyled, 
  ColStyled, 
  ColStyledTwo, 
  ColStyledThree, 
  LabelStyled, 
  InputStyled, 
  SelectStyled, 
  ButtonStyled, 
  ButtonStyledThree, 
  ListStyled, 
  ItemStyled 
} from './styles';

import { actions as questionActions } from '../../redux/modules/question';

import * as questionSelectors from '../../redux/selectors/question';

import { getModulesbyFamilies } from '../../redux/api/question';

const proptypes = {
  loading: PropTypes.bool.isRequired, 
  getQuestions: PropTypes.func.isRequired,
  getFamilies: PropTypes.func.isRequired,
};

const categoryIdOther = 6;

function SearchQuestion(props) {
  const history = useHistory();
  const {
    getQuestions,
    getFamilies,
    loading,
  } = props

  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [openFilters, setOpenFilters] = useState(false);
  const [questions, setQuestions] = useState({});
  const [families, setFamilies] = useState([]);
  const [modules, setModules] = useState([]);

  function filtersFormatter(filters) {
    const formatted = {};
  
    for (const key in filters) {
        if (filters.hasOwnProperty(key)) {
          formatted[`filter[${key}]`] = filters[key];
        }
    }
  
    return formatted;
  }

  useEffect(() => {
    const filtersFormatted = filtersFormatter(filters);
    
    getQuestions({...filtersFormatted, page}, (questions) => {
      setQuestions(questions);
    });
  }, [filters, page, getQuestions]);

  useEffect(() => {
    getFamilies((families) => {
      const comboFamilies = families.map(family => ({
        value: family.familie_id,
        label: family.name
      }));

      setFamilies(comboFamilies);
    });
  }, [getFamilies]);

  const handleChangeFamily = async (value) => {
    const requestModules = await getModulesbyFamilies(value);
    const keys = Object.keys(requestModules);
    const dynamicKey = keys[0];
    const arrayModules = requestModules[dynamicKey];
    const comboModules = arrayModules.map(module => ({
      value: module.id,
      label: module.title
    }));
      
    setModules(comboModules);
  };

  const handleSubmit = (values) => {
    setFilters(values);
    setPage(1);
  };

  return (
    <Formik initialValues={{}}>
      {({ values, handleChange, setFieldValue }) => (
        <Form style={{ width: '100%' }}>
          <RowStyled gutter={[4, {sm: 0, xl: 20}]} align="middle">
            <Col xs={24} sm={24} xl={6}>
              <LabelStyled htmlFor='term'>Enunciado ou código</LabelStyled>
              <InputStyled
                id='term'
                name="term"
                onChange={handleChange}
                placeholder="Insira aqui..."
                allowClear
              />
            </Col>
            <ColStyled open={openFilters} xs={24} sm={24} xl={6}>
              <SelectStyled
                className='ant-select-customize-input'
                placeholder={<><AuditOutlined /> Certificacões...</>}
                options={families}
                onChange={(value) => {
                  setFieldValue('families', value);
                  setFieldValue('module_id', undefined);
                  
                  
                  if(value && value !== categoryIdOther) {
                    handleChangeFamily(value);
                  } else {
                    setModules([]);
                  }
                }}
                allowClear
              />
            </ColStyled>
            <ColStyled open={openFilters} xs={24} sm={24} xl={6}>
              <SelectStyled
                className='ant-select-customize-input'
                placeholder={<><AppstoreOutlined /> Módulo...</>}
                value={!values.families || values.families === categoryIdOther ? undefined : values.module_id}
                options={modules}
                onChange={(value) => setFieldValue('module_id', value)}
                disabled={!modules.length}
                allowClear
              />
            </ColStyled>
            <Col xs={24} sm={24} xl={6}>
              <ButtonStyled type="submit" onClick={() => handleSubmit(values)}>Buscar</ButtonStyled>
            </Col>
            <ColStyledTwo xs={24} sm={24} xl={4}>
              <ButtonStyledThree type="default" onClick={() => setOpenFilters(!openFilters)}>
                {openFilters ? <><ArrowUpOutlined /> Menos filtros</> : <><ArrowDownOutlined /> Mais filtros</>}
              </ButtonStyledThree>
            </ColStyledTwo>
          </RowStyled>
          {!loading ? (
            <>
              <Row>
                <ColStyledThree span={24}>
                  {questions.total > 0 ? <p>{questions.total} {questions.total > 1 ? 'questões' : 'questão'}</p> : !questions?.data ? <p>Carregando...</p> : <p>Nenhuma questão encontrada!</p>}
                </ColStyledThree>
              </Row>
              {questions?.data?.length > 0 && (
                <Row>
                  <Col span={24}>
                    <ListStyled
                      dataSource={questions.data}
                      renderItem={item => (
                        <ItemStyled onClick={() => history.push(`/questions/${item.id_questao}`)}>
                          <List.Item.Meta avatar={item.codigo} description={ item.enunciado?.length > 237 ? `${item.enunciado.substring(0, 237)}...` : item.enunciado } />
                        </ItemStyled>
                      )}
                      pagination={{
                        onChange: page => setPage(page),
                        pageSize: questions?.per_page || 0,
                        total: questions?.total || 0,
                        current: questions?.current_page || 1,
                        showSizeChanger: false
                      }}
                    />
                  </Col>
                </Row>
              )}
            </>
          ) : (
            <Row>
                <Col span={22} style={{paddingLeft: '24px'}}>
                  <p>Carregando...</p>
                </Col>
              </Row>
          )}
        </Form>
      )}
    </Formik>   
  );
}

SearchQuestion.propTypes = proptypes;

const mapStateToProps = (state, props) => ({
  loading: questionSelectors.isLoading(state, props),
});
const mapDispatchToProps = (dispatch) => ({
  getQuestions: (params, next) =>
    dispatch(questionActions.getQuestions(params, next)),
  getFamilies: (next) =>
    dispatch(questionActions.getFamilies(next)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchQuestion);
